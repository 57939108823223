/*
 * @Description: 
 * @Author: lja
 * @Date: 2021-08-18 08:33:56
 * @LastEditors: weig
 * @LastEditTime: 2021-12-17 11:11:58
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//查询内容类型分页
export const getPage=(params)=>{
    return request.apiGet(`/api${API_VERSION}/Article/ArticleType/GetPage`,params)
} 
//查询单条内容分类
export const get=(params)=>{
    return request.apiGet(`/api${API_VERSION}/Article/ArticleType/Get`, params)
} 
//修改内容分类
export const editType=(params)=>{
    return request.apiPut(`/api${API_VERSION}/Article/ArticleType/Update`,params)
} 
//添加内容分类
export const addType=(params)=>{
    return request.apiPost(`/api${API_VERSION}/Article/ArticleType/Add`,params)
} 
//删除内容分类
export const removeType=(params)=>{
    return request.apiDelete(`/api${API_VERSION}/Article/ArticleType/Delete?articleTypeId=${params}`)
} 
//批量删除内容分类
export const batchRemoveType=(params)=>{
    return request.apiPut(`/api${API_VERSION}/Article/ArticleType/BatchDelete`,params)
}