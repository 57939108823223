<!--
 * @Description: 
 * @Author: lja
 * @Date: 2021-09-18 10:47:11
 * @LastEditors: weig
 * @LastEditTime: 2022-02-23 12:20:05
-->
<template>
  <div>
    <!-- 面包屑 begin -->
    <eup-crumbs icon="Notebook" firstCrumbs="内容管理" secondCrumbs="内容分类" />
    <!-- 面包屑 end -->
    <!-- 内容区域 begin -->
    <!-- 表单内容 -->
    <div class="container">
      <!-- 查询 -->
      <div class="handle-box">
          <el-form :model="state.query" :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:article:articletype:getpage`,`api${state.VIEW_VERSION}:article:articletype:add`,`api${state.VIEW_VERSION}:article:articleinfo:batchdelete`])" >
             <template v-if="checkPermission([`api${state.VIEW_VERSION}:article:articletype:getpage`])">
                 <el-form-item label="内容类型">
                  <el-select v-model="state.query.ArticleCategory" placeholder="类型"  class="handle-select mr10"  @change="getArticleCategoryid">
                  <el-option
                   v-for="item in state.optionsType"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
                   </el-form-item>
                 <el-form-item label="是否有效">
                <el-select v-model="state.query.isActive" placeholder="状态"   class="handle-select mr10" @change="handleSearch">
                  <el-option v-for="item in state.GetIsActive" :key="item.value" :label="item.label" :value="item.value" ></el-option>
                </el-select>
              </el-form-item>
                <el-form-item label="分类名称">
                   <el-input v-model="state.query.key" placeholder="分类名称"   @change="handleChangeQuery">
                    <template #prefix>
                      <i class="el-input__icon el-icon-search" />
                    </template>
                  </el-input> 
                  </el-form-item>
              <el-form-item style="margin-right:10px;">
                <el-button type="primary" icon="Search" @click="handleSearch">查询</el-button>
               </el-form-item> 
               </template>
              <el-form-item style="margin-right:10px;" v-if="checkPermission([`api${state.VIEW_VERSION}:article:articletype:add`])">
                <el-button type="primary" icon="Plus" @click="handleClickAddData">新增</el-button>
              </el-form-item>
              <el-form-item style="margin-right:10px;">
              <el-button
                  type="danger"
                  icon="Delete"
                  class="handle-del mr10"
                  @click="handleClickBatchDelete"
                  v-if="checkPermission([`api${state.VIEW_VERSION}:article:articletype:batchdelete`])"
                  >批量删除
                  
              </el-button>
              </el-form-item>
          </el-form>
      </div>

      <!-- 列表 -->
      <el-table
          border
          highlight-current-row
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          row-key="articleTypeId"
          v-loading="state.loading"
          :data="state.tableData"
          :default-expand-all="false"
          :expand-row-keys="state.expandRowKeys"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          @select="onSelect"
           @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="60" align="center"></el-table-column>
         <el-table-column prop="articleTypeName" min-width="260" label="分类名称"  align="center" ></el-table-column>
         <el-table-column prop="parentArticleTypeName" min-width="260" label="上级分类"  align="center" ></el-table-column>
        <el-table-column prop="isActive" label="是否有效" min-width="160" align="center" >
            <template #default="{row}">
                  <el-tag :type="row.isActive == state.isActive.yes ? 'success' : 'danger'" disable-transitions>
                      {{ row.isActive == state.isActive.yes ? '有效' : '无效' }}
                  </el-tag>
              </template>
        </el-table-column>
        <el-table-column prop="sequence" min-width="160" label="排序" align="center"  ></el-table-column>


          <el-table-column label="操作" min-width="240" align="center" fixed="right" v-if="checkPermission([`api${state.VIEW_VERSION}:article:articletype:update`,`api${state.VIEW_VERSION}:article:articletype:delete`])">
                  <template #default="{ $index, row }">
                    <el-button
                        type="primary"
                        icon="Edit"
                        @click="handleEdit($index,row)"
                         v-if="checkPermission([`api${state.VIEW_VERSION}:article:articletype:update`])"
                    >编辑</el-button>
                    <el-button
                        type="danger"
                        icon="Delete"
                        @click="handleClickDelete(row)"
                         v-if="checkPermission([`api${state.VIEW_VERSION}:article:articletype:delete`])"
                        class="ml5"
                    >删除</el-button>
                </template>
          </el-table-column>
      </el-table>
      <!-- 分页 begin-->
      <EupPagination
          :current-page="state.pageIndex"
          :pagesizes="[10,20,50,100]"
          :pagesize="state.pageSize"
          :total="state.pageTotal"
          @getPageData="datasource"
          @resPageData="resPageData">
      </EupPagination>
      <!-- 分页 end-->
    </div>
    <!-- 内容区域 end -->
    <!-- 添加/编辑窗口 begin -->
    <el-dialog 
      :title="state.dialogTitle"
      v-model="state.addDialogFormVisible"
      width="60%"
      @close="closeEditForm()"
      :fullscreen="state.dialogFull"
      >
      <el-form
        ref="addForm"
        :model="state.form"
        :rules="state.addFormRules"
        label-width="100px"
        :inline="false"
      >
       <el-form-item prop="ArticleCategory" label="内容类型">    
                  <el-select v-model="state.form.articleCategory" placeholder="请选择"  @change="addArticleCategoryid ">
                  <el-option
                   v-for="item in state.optionsType"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
            </el-form-item>
    <el-form-item prop="parentIds" label="上级分类">
          <el-cascader
            :key="state.addFormKey"
            v-model="state.form.parentIds"
            :options="state.modules"
            :props="{checkStrictly: true, value: 'articleTypeId'}"
            filterable
            style="width:100%;"
          />
        </el-form-item> 
          <el-form-item label="分类名称" prop="articleTypeName">
          <el-input v-model="state.form.articleTypeName" auto-complete="off" />
        </el-form-item>
        <el-form-item label="排序" prop="sequence">
          <el-input v-model="state.form.sequence" auto-complete="off" />
        </el-form-item>
        <el-form-item label="是否有效" prop="isActive">
          <el-switch v-model="state.form.isActive"/>
        </el-form-item>

      </el-form>
      <template #title>
        <div class="avue-crud__dialog__header">
            <span class="el-dialog__title">
            <span style="display:inline-block;width:3px;height:20px;margin-right:5px; float: left;margin-top:2px"></span>
              {{state.dialogTitle}}
            </span>
       <div class="avue-crud__dialog__menu" @click="state.dialogFull? state.dialogFull=false: state.dialogFull=true">
            <el-icon v-if="!state.dialogFull" title="全屏"><FullScreen/></el-icon>
            <el-icon v-else title="缩小"><CopyDocument/></el-icon>
          </div> 
        </div>
      </template>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeEditForm()">取 消</el-button>
          <el-button type="primary" @click="addDialogFormSave()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加/编辑窗口 end -->
  </div>
</template>

<script>
import { reactive, onMounted, ref } from 'vue'
import { ElMessage,ElMessageBox } from 'element-plus'
import {elConfirmDialog, IsNullOrEmpty} from "@/common/js/comm"
import { getPage,get,editType,addType,removeType,batchRemoveType} from '@/serviceApi/article/articleType'
import { treeToList, listToTree, getTreeParents } from '@/utils/tool'
import {useStore} from 'vuex'
import EupPagination from "@/components/EupPagination.vue"
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import EnumConfig from "@/enum/EnumConfig"

var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;

export default {
  name: 'ArticleType',
  components:{
    EupPagination: EupPagination,
    EupCrumbs: EupCrumbs
  },
  setup(props, context) {
    const multipleTable = ref(null);
    const addForm = ref(null);
    const state = reactive({
        query: {
          key:'',
           ArticleCategory:1,
           isActive:'0',
        },
        multipleSelection: [],    
        VIEW_VERSION: VIEW_VERSION,
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
        tableData: [],
        form: {
            articleTypeName:'',
            sequence:1,
            isActive:true,
            articleCategory:1,
            parentIds: []
        },
        modules: [
            ],
          isActive: {
          yes: 1,
          no: 2
        },
        addFormRules:{
          parentIds: [{ required: true, message: '请选择上级分类', trigger: 'change' }],
          sequence: [{ required: true, message: '请输入排序', trigger: 'blur' }], 
          articleTypeName: [{ required: true, message: '请输入分类名称', trigger: 'blur' }]
        },
         GetIsActive: [
              { value: '0', label: '全部' },
              { value: '1', label: '有效' },
              { value: '2', label: '无效' },
            ],
       optionsType:[
        { value: 1, label:'文章'},
        { value: 2, label:'通知'},
        { value: 3, label:'系统帮助'}
      ],
        loading: false,
        store: {}, //vuex全局状态管理对象
        addDialogFormVisible: false, //是否显示对话框
        dialogTitle: "", //对话框标题
        dialogType: 0, //对话框类型, 0:编辑框  1:新增框
        dialogFull: false, //是否为全屏 Dialog
        expandRowKeys: [],
        articleTypeTree: [],
        addFormKey: 1,
        type:'',
        Method:'',
        FrontPageData: [],//所有的视图数据
    });

     const articleCategory = (row) => {
      if (row.articleCategory == 1) {
        return " 文章";
      } else if (row.articleCategory == 2) {
        return " 通知";
      } else {
        return "系统帮助";
      }
    };
    onMounted(() => {
      datasource();
      state.store = useStore();
    });

    /**
     * @description 获取表单数据
     * @author lja
     */
    const datasource = () => {
      state.loading = true;
      let params={
        "currentPage": state.pageIndex,
        "pageSize": state.pageSize,
        "Filter.ArticleCategory":state.query.ArticleCategory,
        "Filter.ArticleTypeName":state.query.key,
        "Filter.isActive":state.query.isActive=='0'? null:state.query.isActive,
      }
     getPage(params).then(function(res){
        if (res.code == 1) {
            state.pageTotal = res.data.total;//初始化列表数据总数
              var field=[];//拿取修改的字段
               var list=[];//保存修改后的数据
               for(var i = 0; i < res.data.list.length; i++){
              field.push(res.data.list[i].parentArticleTypeName);
              var obj = {
                parentArticleTypeName:res.data.list[i].parentArticleTypeName!="" ? res.data.list[i].parentArticleTypeName:'顶级',
                articleTypeId:res.data.list[i].articleTypeId,
                isActive:res.data.list[i].isActive,
                articleTypeName:res.data.list[i].articleTypeName,
                parentId:res.data.list[i].parentId,
                sequence:res.data.list[i].sequence,
                articleCategory:res.data.list[i].articleCategory,
                };
                list.push(obj);
                }
          state.tableData=list;
          state.tableData.forEach((data, i) => {
                    data.num = i + 1;
                });
          state.multipleSelection= [];
        } else {
          ElMessage.error(res.msg);
        }
       state.loading = false;
      });
    }
    
     /**
     * @description 获取所选类型的上级分类
     * @author lja
     */
    const addArticleCategoryid = ()=>{
      let params={
        "Filter.ArticleCategory":state.form.articleCategory,
        "PageSize":10000000
      }

          getPage(params).then(function(res){
           let list = JSON.parse(JSON.stringify(res.data.list));
           list.forEach(l => {
              l._loading = false
            });
            var a=[];
               var b=[];
               for(var i = 0; i < list.length; i++){
              a.push(list[i].articleTypeName);
              var obj = {
                label: list[i].articleTypeName,
                articleTypeId:list[i].articleTypeId,
                parentId:list[i].parentId,
                articleCategory:list[i].articleCategory,
                };
                b.push(obj);
                }
              state.modules = listToTree(JSON.parse(JSON.stringify(b)),{
              articleTypeId: 0,
              parentId: 0,
              label: '顶级'
            }, "articleTypeId")
               const tree = listToTree(list, null, "articleTypeId");
               state.articleTypeTree=tree;
            state.multipleSelection= [];
          });
    }
    /**
     * @description 子组件返回分页数据
     * @author lja
     * @param {Object} obj
     */
    const resPageData = (obj) =>{
        state.pageIndex = obj.currPage;
        state.pageSize = obj.pageSize;
    }
    /**
     * @description 改变查询条件
     * @author lja
     * @param {String} val 值
     */
    const handleChangeQuery = (val)=>{
      //刷新列表
      datasource();
    }

    /**
     * @description 查询所属类别
     * @author lja
     * @param {String} val 值
     */
    const getArticleCategoryid = ()=>{
          state.form.articleCategory=state.query.ArticleCategory
          datasource();
        };

    /**
     * @description 单行选中
     * @author lja
     * @param {Array} selection 当前选中行的上级父级节点
     * @param {Object} row 当前行数据
     */
    const onSelect = (selection, row) =>{
      const checked = selection.some(s => s.id === row.id);
      if (row.children && row.children.length > 0){
        const rows = treeToList(row.children);
        rows.forEach(row => {
          multipleTable.value.toggleRowSelection(row, checked);
        });
      }
      state.multipleSelection = multipleTable.value.store.states.selection.value;
    }

    /**
     * @description 查询
     * @author lja
     */
    const handleSearch =()=>{
        state.pageIndex = 1
        datasource();
    }

    /**
     * @description 新增
     * @author lja
     */
    const handleClickAddData = () =>{
      state.addDialogFormVisible = true;
      state.dialogType = 1;
      state.dialogTitle = "新增";
       addArticleCategoryid()
    }

    /**
     * @description 批量删除
     * @author lja
     */
    const handleClickBatchDelete = () =>{
      if (state.multipleSelection.length == 0) {
        return ElMessage.error("请选择要删除的数据");
      }
      elConfirmDialog(
        ElMessageBox,
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        "",
        () => {
          batchRemoveType(state.multipleSelection)
            .then((res) => {
              if (res.code == 1) {
                ElMessage.success("删除成功");
                datasource();
              } else {
                ElMessage.error("删除失败!");
              }
            })
            .catch((err) => {
              ElMessage.error(err.msg);
            });
        },
        () => {
          ElMessage.info("取消删除!");
        }
      );
    }

   
    const handleSelectionChange = (val) => {
      state.multipleSelection = val.map((s) => {
        return s.articleTypeId;
      })}
    /**
     * @description 编辑
     * @author lja
     * @param {Number} index 行号
     * @param {Object} row 行数据
     */
    async function handleEdit (index, row){
       state.form.articleCategory=row.articleCategory
       addArticleCategoryid() 
      state.addDialogFormVisible = true;
      state.dialogType = 0;
      state.dialogTitle = "编辑";
        let params={
        'id':row.articleTypeId
      }
       const res = await get(params)
      if (res && res.code == 1){
        const parents = getTreeParents(state.articleTypeTree, row.articleTypeId, "children", "articleTypeId");
        const parentIds = parents.map(p => p.articleTypeId);
        parentIds.unshift(0);
        const data = res.data;
        data.parentIds = parentIds;
        state.form.parentIds = data.parentIds;
        state.form.articleTypeName = data.articleTypeName;
       state.form.isActive = data.isActive == state.isActive.yes ? true : false;
        state.form.articleTypeId = row.articleTypeId;
        state.form.sequence = data.sequence;
        state.form.ArticleCategory=data.articleCategory;
        ++state.addFormKey;
      } else {
        ElMessage.error(res.msg)
      }
    }

    /**
     * @description 删除
     * @author lja
     * @param {Object} row 行数据
     */
    const handleClickDelete = (row) =>{
       var id = row.articleTypeId;
      elConfirmDialog(ElMessageBox,'此操作将永久删除该数据, 是否继续?','提示', '', ()=>{
        removeType(id).then(res =>{
          if(res.code == 1){
              ElMessage.success("删除成功!");
              datasource();
          } else {
              ElMessage.error(res.msg);
          }
        });
      },);
    }

    /**
     * @description 关闭对话框
     * @author lja
     */
    const closeEditForm = ()=>{
        state.addDialogFormVisible = false;
       /*  state.noparent=true; */
         addForm.value.resetFields();//清空表单数据
        state.dialogType = 0;
        ++state.addFormKey;
        state.form={
            articleTypeName:'',
            sequence:'1',
            isActive:true,
           articleCategory:state.query.ArticleCategory, 
            articleTypeId:0,
             parentIds: []
        };
    }

    /**
     * @description 确认保存
     * @author lja
     */
    const addDialogFormSave = ()=>{
      if(IsNullOrEmpty(state.form.articleTypeName)){
        ElMessage.error("分类名称不能为空!");
        return;
      }
       if(IsNullOrEmpty(state.form.sequence)){
        ElMessage.error("排序不能为空!");
        return;
      }
      const copyData = JSON.parse(JSON.stringify(state.form))
      const parentId = copyData.parentIds.pop();
      
      if (copyData.articleTypeId === parentId && parentId !== 0){
        ElMessage.error("上级分类不能是自己!");
        state.addDialogFormVisible = false;
        return;
      }
      let params = {};
      switch (state.dialogType){
        case 0://编辑
            params = {
              "parentId": parentId,
              "articleTypeName": state.form.articleTypeName,
              "sequence": state.form.sequence,
              "isActive": state.form.isActive ? state.isActive.yes : state.isActive.no,
              "articleCategory":state.form.articleCategory,
              "articleTypeId": state.form.articleTypeId,
              updateUserId: state.store.getters.userInfo.userId,
            }
            editType(params).then(res =>{
                if(res.code == 1){
                    ElMessage.success("修改成功");
                    state.addDialogFormVisible = false;
                    datasource();
                } else {
                    ElMessage.error(res.msg);
                }
            });
            break;
        case 1://新增
              params = {
              "articleCategory":state.form.articleCategory,
              "parentId": parentId,
              "articleTypeName": state.form.articleTypeName,
              "sequence": state.form.sequence,
              "isActive": state.form.isActive ? state.isActive.yes : state.isActive.no,
              }
              addType(params).then(res =>{
                  if(res.code == 1){
                      ElMessage.success("添加成功");
                      state.addDialogFormVisible=false;
                      datasource();
                  } else {
                      ElMessage.error(res.msg);
                  }
              });
              break;
        default:
          break;
      }
    }
    return {
      state,
      handleSearch,
      handleClickAddData,
      handleClickBatchDelete,
      handleEdit,
      handleClickDelete,
      datasource,
      getArticleCategoryid,
      handleSelectionChange,
      articleCategory,
      handleChangeQuery,
      multipleTable,
      addForm,
      addArticleCategoryid,
      onSelect,
      closeEditForm,
      addDialogFormSave,
      resPageData,
    }
  },
}

</script>
<style>
</style>